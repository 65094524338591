<template>
  <div class="footer-wrap">
    <!-- 左侧 -->
    <div class="foot-box">
      <div class="foot-col-1">
        <div class="foot-logo">
          <img :src="mini_logo" />
        </div>

        <div class="foot-information">
          <div class="foot-message">
            <img src="@/assets/images/phone-foot.png" alt="" />
            <span>400 9696 179</span>
          </div>
          <div class="foot-message">
            <img src="@/assets/images/mail-foot.png" alt="" />
            <span>info@carbongram.com</span>
          </div>
        </div>

        <div class="foot-introduction">
          <div class="foot-introduction-us">
            {{
              this.$store.state.nowLanguage
                ? "上海观理碳科技有限公司"
                : "Carbongram Shanghai Co., Ltd"
            }}
          </div>
          <div class="foot-introduction-us">
            Copyright &copy; 2020-2023 Carbongram
          </div>
          <div class="foot-introduction-us usOne" @click="openOne()">
            沪ICP备2022000115号-1
          </div>
          <div class="police_number" @click="openTwo()">
            <img src="@/assets/home/background/police_tag.png" alt="" />
            公安备案号31011002005944号
          </div>
        </div>
      </div>
      <div class="foot-col-2">
        <div class="foot-link">
          <!-- 菜单分列 -->
          <div class="foot-link-col" v-for="(link, i) in links" :key="i">
            <div
              class="foot-link-title"
              @click="toAbout(i)"
              @mouseenter="(e) => handleLinkPop(e, link, i)"
            >
              {{
                this.$store.state.nowLanguage ? link.title : link.EnglishTitle
              }}
            </div>
            <!-- 子菜单 -->
            <div
              class="foot-link-list"
              @mouseenter="(e) => handleLinkPop(e, link, i)"
            >
              <div
                class="foot-link-item"
                v-for="(l, j) in link.links"
                :key="j"
                @click="toDetail(j)"
              >
                <a>{{
                  this.$store.state.nowLanguage ? l.title : l.EnglishTitle
                }}</a>
              </div>
            </div>
            <!-- ./end 子菜单 -->
          </div>
        </div>
        <div class="foot-qrcode">
          <div class="foot-qrcode-box">
            <img :src="qrcode_1" alt="" />
            <p :class="!this.$store.state.nowLanguage ? 'en-word' : ''">
              {{
                this.$store.state.nowLanguage
                  ? "关注公众号"
                  : "Follow us on WeChat"
              }}
            </p>
          </div>
          <div class="foot-qrcode-box">
            <img :src="qrcode_2" alt="" />
            <p :class="!this.$store.state.nowLanguage ? 'en-word' : ''">
              {{
                this.$store.state.nowLanguage
                  ? "联系企业微信"
                  : "Connect with us"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="foot-link-app">
      <!-- 菜单分列 -->
      <div class="foot-link-col" v-for="(link, i) in links" :key="i">
        <div
          class="foot-link-title"
          @click="toAbout(i)"
          @mouseenter="(e) => handleLinkPop(e, link, i)"
        >
          {{ this.$store.state.nowLanguage ? link.title : link.EnglishTitle }}
        </div>
        <!-- 子菜单 -->
        <div
          class="foot-link-list"
          @mouseenter="(e) => handleLinkPop(e, link, i)"
        >
          <div
            class="foot-link-item"
            v-for="(l, j) in link.links"
            :key="j"
            @click="toDetail(j)"
          >
            <a>{{
              this.$store.state.nowLanguage ? l.title : l.EnglishTitle
            }}</a>
          </div>
        </div>
        <!-- ./end 子菜单 -->
      </div>
    </div>
  </div>
  <div class="bottom-introduction">
    <div class="bottom-introduction-us">
      {{
        this.$store.state.nowLanguage
          ? "上海观理碳科技有限公司"
          : "Carbongram Shanghai Co., Ltd"
      }}
    </div>
    <div class="bottom-introduction-us">
      Copyright &copy; 2020-2023 Carbongram
    </div>
    <div class="bottom-introduction-us" @click="openOne()">
      沪ICP备2022000115号-1
    </div>
    <div class="police_number" @click="openTwo()">
      <img src="@/assets/home/background/police_tag.png" alt="" />
      公安备案号31011002005944号
    </div>
  </div>
</template>

<script>
import links from "./data/links.json";
export default {
  setup() {
    return {
      pop_idx: 0,
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },
  },
  data() {
    return {
      mini_logo: require("@/assets/images/logo.png"),
      qrcode_1: require("@/assets/home/icons/Official_Public_Account.png"),
      qrcode_2: require("@/assets/home/icons/Official_Enterprise_WeChat.png"),
      links,
    };
  },
  mounted() {
    // console.log(JSON.stringify(this.links));
  },
  methods: {
    openOne() {
      window.open("https://beian.miit.gov.cn");
    },
    openTwo() {
      window.open(
        "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002005944"
      );
    },
    // 底部跳转
    toAbout(i) {
      console.log(i);
      // 跳转到产品
      if (i == 0) {
        this.$router.push("/product");
        document.documentElement.scrollTop = 0;
      }
      // 跳转到ccer首页
      if (i == 2) {
        window.open("https://ccer.com/#/home");
      }
      // 跳转到开发者
      if (i == 3) {
        this.$router.push("/developer");
        document.documentElement.scrollTop = 0;
      }
      // 跳转到关于我们
      if (i == 4) {
        this.$router.push("/about");
        document.documentElement.scrollTop = 0;
      }
    },
    handleLinkPop(e, link, i) {
      // console.log(e, link, i);
      this.pop_idx = i;
    },
    toDetail(j) {
      console.log(this.pop_idx, j);
      if (this.pop_idx == 2) {
        let url =
          j == 0
            ? "https://ccer.com/#/product"
            : "https://ccer.com/#/transaction";
        window.open(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-wrap {
  padding: 60px 12.5%;
  width: 100%;
  height: 466px;
  background: #25386a;
  box-sizing: border-box;
  text-align: left;
  .foot-box {
    width: 100%;
    // max-width: 1440px;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
  }
  .foot-col-1 {
    margin-right: 0%;
    margin-bottom: 20px;
    min-width: 220px;
    color: #fff;
    font-size: 18px;
    .foot-logo {
      width: 157px;
      height: 56px;
      img {
        width: 100%;
      }
    }
    .foot-information {
      margin-top: 38px;
      .foot-message {
        display: flex;
        margin-top: 12px;
        img {
          width: 25px;
          height: 25px;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          line-height: 25px;
          letter-spacing: 1px;
          margin-left: 12px;
        }
      }
    }
    .foot-introduction {
      margin-top: 36px;
      &-us {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        line-height: 25px;
        letter-spacing: 1px;
        margin-top: 10px;
      }
      .usOne {
        cursor: pointer;
        user-select: none;
      }
      .police_number {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        line-height: 25px;
        letter-spacing: 1px;
        margin-top: 10px;
        cursor: pointer;
        user-select: none;
        display: flex;
        justify-content: flex-start;
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }
  }
  .foot-col-2 {
    // flex: 1;
    display: flex;
    justify-content: right;
  }
  .foot-qrcode {
    margin-left: 4.5%;
    &-box {
      margin-bottom: 20px;
      text-align: center;
      img {
        width: 110px;
        height: 110px;
      }
      p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        text-align: center;
        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
        letter-spacing: 1px;
      }
      .en-word {
        font-size: 12px !important;
      }
    }
  }
  .foot-link {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 26px;
    letter-spacing: 1px;
    &-col {
      min-width: 120px;
      // width: 100%;
      margin-left: 1%;
    }
    &-title {
      font-size: 14px;
      cursor: pointer;
    }
    &-list {
      margin-top: 32px;
    }
    &-item {
      margin-bottom: 12px;
      font-size: 12px;
      line-height: 18px;
      height: 36px;
    }
  }
  .foot-link-app {
    display: none;
    // display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 26px;
    letter-spacing: 1px;
    &-col {
      min-width: 110px;
    }
    &-title {
      font-size: 20px;
    }
    &-list {
      margin-top: 10px;
    }
    &-item {
      margin-bottom: 12px;
      font-size: 12px;
    }
  }
}
.bottom-introduction {
  display: none;
  width: 100%;
  height: 120px;
  text-align: center;
  padding-top: 20px;
  &-us {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    letter-spacing: 1px;
  }
}
@media screen and (max-width: 1440px) {
  .footer-wrap {
    padding: 60px 9.375%;
    .foot-link {
      &-col {
        min-width: 100px;
      }
    }
  }
  .foot-box {
    max-width: 1170px;
  }
}
@media screen and (max-width: 1170px) {
  .footer-wrap {
    padding: 60px 16.67%;
    height: 605px;
    .foot-box {
      width: 100%;
      max-width: 780px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .foot-col-1 {
      // margin-right: 7.5%;
      margin-bottom: 20px;
      // width: 200px;
      color: #fff;
      font-size: 18px;
      .foot-information {
        margin-top: 23px;
      }
      .foot-introduction {
        display: none;
      }
    }
    .foot-col-2 {
      // flex: 1;
      display: flex;
      justify-content: right;
    }
    .foot-qrcode {
      margin-left: 2.5%;
      display: flex;
      justify-content: space-between;
      margin-right: 0;
      &-box {
        margin-bottom: 0px;
        margin-left: 20px;
        img {
          width: 110px;
          height: 110px;
        }
        p {
          margin: 0;
          padding: 0;
          font-size: 14px;
          text-align: center;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
          letter-spacing: 1px;
        }
      }
    }
    .foot-link {
      display: none;
      &-col {
        min-width: 100px;
      }
      &-list {
        margin-top: 10px;
      }
    }
    .foot-link-app {
      display: flex;
      margin-top: 50px;
    }
  }
  .bottom-introduction {
    display: block;
    width: 100%;
    height: 144px;
    text-align: center;
    padding-top: 20px;
    &-us {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 24px;
      letter-spacing: 1px;
    }
    .usOne {
      cursor: pointer;
      user-select: none;
    }
    .police_number {
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      color: #999999;
      line-height: 24px;
      letter-spacing: 1px;
      // margin-top: 10px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.mobile {
  .footer-wrap {
    padding: 60px 16%;
    height: 560px;
    .foot-box {
      max-width: 540px;
    }
    .foot-link-app {
      display: flex;
      margin-top: 34px;
    }
  }
  @media screen and (max-width: 720px) {
    .footer-wrap {
      padding: 60px 12%;
    }
  }
  @media screen and (max-width: 640px) {
    .footer-wrap {
      padding: 60px 10%;
    }
  }
  @media screen and (max-width: 610px) {
    .footer-wrap {
      padding: 60px 8%;
    }
  }
  @media screen and (max-width: 580px) {
    .footer-wrap {
      padding: 60px 4.8%;
    }
  }
  @media screen and (max-width: 540px) {
    .footer-wrap {
      padding: 40px 18%;
      height: 367px !important;
      .foot-box {
        width: 100%;
        max-width: 375px !important;
        display: flex;
        flex-flow: column !important;
        justify-content: left !important;
        flex-wrap: wrap;
      }
      .foot-col-1 {
        // margin-right: 7.5%;
        margin-bottom: 20px;
        // width: 360px;
        color: #fff;
        font-size: 18px;
        .foot-logo {
          width: 106px;
          height: 37px;
          img {
            width: 100%;
          }
        }
        .foot-information {
          .foot-message {
            span {
              font-size: 12px;
            }
          }
        }
      }
      .foot-col-2 {
        justify-content: left;
      }
      .foot-qrcode {
        width: 100%;
        display: flex;
        justify-content: left;
        margin-right: 0;
        margin-left: 0;
        &-box {
          margin-bottom: 0px;
          margin-left: 0px;
          margin-right: 18px;
          p {
            font-size: 12px;
          }
        }
      }
      .foot-link-app {
        display: none !important;
        margin-top: 50px;
      }
    }
    .bottom-introduction {
      &-us {
        font-size: 10px !important;
      }
      .police_number {
        font-size: 10px !important;
      }
    }
  }
}
</style>
