<template>
  <div :class="classObj" class="app-wrap">
    <Mask v-if="is_mask" />
    <Header class="header-wrap" />
    <div class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <navbar />
      </div>
      <app-main />
    </div>
    <Footer class="footer-wrap" />
  </div>
</template>

<script>
import Mask from "@/components/mask";
import { Header, AppMain, Footer } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";

export default {
  name: "Layout",
  components: {
    Mask,
    Header,
    Footer,
    AppMain,
  },
  mixins: [ResizeMixin],
  computed: {
    is_mask() {
      return this.$store.state.app.is_mask;
    },
    device() {
      return this.$store.state.app.device;
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    classObj() {
      return {
        mobile: this.device === "mobile",
      };
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";
.app-wrap {
  user-select: none;
  @include clearfix;
  position: relative;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  font-family: HarmonyOS_Sans_SC_Regular;
 
  &.mobile {
    width: 100%;
    min-width: 360px;
  }
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
